/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColDef, ColGroupDef, RowNode, ValueFormatterParams } from '@ag-grid-enterprise/all-modules'
import * as FileboxManager from '@cck/backend/dist/filebox/FileboxManager'
import { getAll as LoadStaffs } from '@cck/backend/dist/rcm/StaffManager'
import { formatBytes } from '@cck/common/dist/data/BaseFile'
import { Staff, StaffValueFormatter } from '@cck/common/dist/data/Staff'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Button from 'antd/lib/button'
import Typography from 'antd/lib/typography'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'

import { grey } from '../../base/color'
import AlertMessage, { AlertMessageHandler } from '../common/AlertMessage'
import CircleBackdrop from '../common/CircleBackdrop'
import CommonAgGrid from '../common/CommonAgGrid'
import NoticeTextView from '../common/NoticeTextView'
import FileUploadBox from './FileUploadBox'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minHeight: 600,
    backgroundColor: 'white',
    border: grey.border,
    margin: theme.spacing(2),
    padding: theme.spacing(1)
  },
  uploadList: {
    flexGrow: 1,
    flexBasis: 0,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: 0
  },
  content: {
    flexGrow: 1,
    flexBasis: 0,
    display: 'flex',
    flexDirection: 'column',
    minHeight: 0
  },
  subheader: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1),
    alignItems: 'center'
  }
}))

function fileNameCellRenderer(params: any): HTMLElement {
  const file = params.data as FileboxManager.CommonFile
  const aElement = document.createElement('a')
  aElement.innerHTML = file.name
  const fileId = file.id
  if (fileId) {
    aElement.onclick = () => FileboxManager.download(fileId)
  }
  const div = document.createElement('div')
  div.appendChild(aElement)
  return div
}

function deleteCellRenderer(params: any): HTMLElement {
  const files: any[] = []
  params.api.forEachNode((node: RowNode) => {
    files.push(node.data)
  })
  const aElement = document.createElement('a')
  aElement.innerHTML = '삭제'
  aElement.onclick = () => params.deleteFile(files, params.data)
  return aElement
}

const FileBox: React.FC = () => {
  const classes = useStyles()
  const [uploadedFiles, setUploadedFiles] = React.useState<FileboxManager.CommonFile[]>([])
  const [files, setFiles] = React.useState<FileboxManager.CommonFile[]>([])
  const [staffs, setStaffs] = React.useState<Staff[]>([])
  const [states, setStates] = React.useState({
    backdrop: false
  })
  const alertRef = React.useRef<AlertMessageHandler>(null)

  const columns = React.useMemo(
    (): (ColDef | ColGroupDef)[] => [
      { headerName: '파일 이름', field: 'name', flex: 2, cellRenderer: fileNameCellRenderer },
      {
        headerName: '작성자',
        field: 'author',
        width: 300,
        valueFormatter: StaffValueFormatter(staffs)
      },
      {
        headerName: '크기',
        field: 'size',
        valueFormatter: (params: ValueFormatterParams) => formatBytes(params.value, 2)
      },
      {
        headerName: '업로드 날짜',
        field: 'updateTime',
        valueFormatter: (params: ValueFormatterParams) => moment(params.value).format('LLL'),
        width: 240,
        sortable: true
      },
      {
        headerName: '삭제',
        field: 'delete',
        width: 120,
        cellRenderer: deleteCellRenderer,
        cellRendererParams: {
          deleteFile: (
            allFiles: FileboxManager.CommonFile[],
            file: FileboxManager.CommonFile
          ): void => {
            setStates({
              ...states,
              backdrop: true
            })
            FileboxManager.remove(file)
              .then(() => {
                alertRef.current?.showAlert('success', '삭제 완료')
                const newFiles = allFiles.filter((item) => item.name !== file.name)
                setUploadedFiles(newFiles)
              })
              .catch((e) => {
                alertRef.current?.showAlert('error', '삭제 실패')
              })
              .finally(() => {
                setStates({
                  ...states,
                  backdrop: false
                })
              })
          }
        }
      }
    ],
    [staffs, states]
  )

  React.useEffect(() => {
    FileboxManager.getAll()
      .then((result) => {
        setUploadedFiles(result)
      })
      .catch((e) => {
        alertRef.current?.showAlert('error', '파일 로드 실패')
      })
    LoadStaffs().then((newStaffs) => {
      setStaffs(newStaffs)
    })
  }, [])

  const uploadFiles = React.useCallback(
    (newFiles: FileboxManager.CommonFile[]) => {
      setStates({ ...states, backdrop: true })
      FileboxManager.upload(newFiles)
        .then(() => {
          alertRef.current?.showAlert('success', '업로드 완료')
          FileboxManager.getAll().then((result) => {
            setUploadedFiles(result)
          })
          setFiles([])
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.log('Failed to upload', e)
          alertRef.current?.showAlert('error', '업로드 실패')
        })
        .finally(() => {
          setStates({ ...states, backdrop: false })
        })
    },
    [states]
  )

  return (
    <div className={classes.root}>
      <div className={classes.uploadList}>
        <div className={classes.subheader}>
          <Typography.Title level={5}>현재 업로드 내역</Typography.Title>
        </div>
        <CommonAgGrid columnDefs={columns} rowData={uploadedFiles} />
      </div>
      <div style={{ marginTop: 4, marginBottom: 4 }} />
      <div className={classes.content}>
        <div className={classes.subheader}>
          <Typography.Title level={5} style={{ marginRight: 8 }}>
            업로드 파일
          </Typography.Title>
          <NoticeTextView>10 MB 이상의 파일은 업로드 되지 않습니다.</NoticeTextView>
          <div style={{ flex: 1 }} />
          <Button
            onClick={() => {
              uploadFiles(files)
            }}
          >
            업로드
          </Button>
        </div>
        <FileUploadBox files={files} setFiles={setFiles} />
      </div>
      <AlertMessage ref={alertRef} />
      <CircleBackdrop open={states.backdrop} />
    </div>
  )
}

export default FileBox
